<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';
import { formatNumber } from '@utils/numbers';
import { i18next } from '@src/translate/i18n';

import CAR_ALERTS from '@graphql/car/queries/list-alerts.gql';

export default {
  name: 'FleetAlerts',
  page: {
    title: i18next.t('alerts.titles.alertsHistories'),
  },
  components: {
    Layout,
    ContentLoading,
    ContentControll,
    FleetHeader,
  },
  data: () => {
    return {
      alerts: {
        count: 0,
        items: [],
      },
      pagination: {
        current: 1,
        total: 10,
      },
      fields: [
        {
          key: 'message',
          label: i18next.t('alerts.titles.alert'),
        },
        {
          key: 'in_home',
          label: i18next.t('alerts.titles.atHome'),
        },
        {
          key: 'odometer',
          label: i18next.t('alerts.titles.odometer'),
        },
        {
          key: 'created_at',
          label: i18next.t('alerts.titles.eventDate'),
        },
        {
          key: 'gsm_signal',
          label: i18next.t('alerts.titles.signalQuality'),
          class: 'pw-100 text-right',
        },
      ],
    };
  },
  computed: {
    carAlerts() {
      return {
        count: this.alerts.count || 0,
        items: this.alerts.items || [],
      }
    }
  },
  methods: {
    setKmFormat(value) {
      return formatNumber(value) + 'km';
    },
    parseData(data) {
      return JSON.parse(data);
    },
    getTrackerStatus(voltage_car, voltage_sensor) {
      if (voltage_sensor >= 2) {
        return {
          status: 'NORMAL',
          badge: 'success',
        };
      } else if (voltage_sensor < 2 && voltage_sensor > 0) {
        return {
          status: 'RECARREGAR',
          badge: 'warning',
        };
      } else if (voltage_car === 0) {
        return {
          status: 'DESCONECTADO',
          badge: 'danger',
        };
      }
    },
  },
  apollo: {
    alerts: {
      query: CAR_ALERTS,
      variables() {
        return {
          id: this.$route.params.id,
          page: this.pagination.current - 1,
          limit: this.pagination.total,
        };
      },
      update: data => data.alertsByCar,
    },
  },
};
</script>

<template>
  <Layout>
    <fleet-header :id="$route.params.id"/>
    <div v-if="!$apollo.queries.alerts.loading" class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-header>
          <h4 class="mt-2">
            {{ $i18n.t('alerts.titles.alertsHistories') }}
          </h4>
        </b-card-header>
        <b-card-body
          class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="carAlerts.items"
            :fields="fields"
          >
            <template v-slot:cell(message)="data">
              <strong>{{ data.item.message_code }}</strong>
              <br>
              <span v-if="data.item.address" class="small text-muted">{{ data.item.address }}</span>
              <span v-else class="small text-muted">Não identificado</span>
            </template>
            <template v-slot:cell(in_home)="data">
              <span v-if="data.item.car_at_address">{{ $i18n.t('alerts.texts.yes') }}</span>
              <span v-else>{{ $i18n.t('alerts.texts.no') }}</span>
            </template>
            <template v-slot:cell(odometer)="data">
              {{ setKmFormat(data.item.odometer) }}
            </template>
            <template
              v-slot:cell(created_at)="data"
            >
              {{ $moment(data.item.created_at).format('DD/MM/YYYY HH:mm:ss') }}
            </template>
            <template v-slot:cell(gsm_signal)="data">
              <div class="signal-base">
                <div :class="'signal-' + data.item.gsm_signal">
                  &nbsp;
                </div>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="alerts.errorType || carAlerts.items.length" class="p-0">
          <content-controll :service="alerts"/>
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading/>
    </div>
    <b-pagination
      v-model="pagination.current"
      align="center"
      size="sm"
      :total-rows="alerts.count"
      :per-page="pagination.total"
    />
  </Layout>
</template>

<style lang="scss">
  .signal-base {
    width: 120px;
    background-color: #c0c0c0;
    height: 20px;
    border-radius: 5px;
  }

  .signal-1,
  .signal-2,
  .signal-3,
  .signal-4,
  .signal-5 {
    height: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .signal-5 {
    width: 100%;
    background-color: #4dbd74;
    border-radius: 5px;
  }

  .signal-4 {
    width: 75%;
    background-color: #4dbd74;
  }

  .signal-3 {
    width: 50%;
    background-color: #ffc107;
  }

  .signal-2 {
    width: 25%;
    background-color: #f86c6b;
  }

  .signal-1 {
    width: 5%;
    background-color: #f86c6b;
  }

  .pw-100 {
    width: 100px !important;
  }

  .pw-140 {
    width: 140px;
  }

  .pw-180 {
    width: 180px;
  }
</style>
